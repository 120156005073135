/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    code: "code",
    pre: "pre",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Software Development Kit (SDK) gives you tools to build integrations to Signatu in Javascript."), "\n", React.createElement(_components.h2, {
    id: "installation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#installation",
    "aria-label": "installation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Installation"), "\n", React.createElement(_components.p, null, "The SDK is available on Github, and is also distributed as the ", React.createElement(_components.code, null, "@signatu/sdk"), " package in NPM. To install:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm i @signatu/sdk\n")), "\n", React.createElement(_components.p, null, "or, if you are using ", React.createElement(_components.code, null, "yarn"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "yarn add @signatu/sdk\n")), "\n", React.createElement(_components.h2, {
    id: "documentation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#documentation",
    "aria-label": "documentation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Documentation"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/sdk/javascript"
  }, "Javascript")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/sdk/nodejs"
  }, "NodeJS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/sdk/react/styles"
  }, "Styling (React)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/sdk/react/policy"
  }, "Policy (React)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/sdk/react/consent"
  }, "Consent (React)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/sdk/react/trackerdetect"
  }, "Trackerdetect (React)")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
